<template>
  <div
    class="atom-flag"
    :class="[
      `is-${variant}`,
      { 'has-icon': icon !== '' },
    ]"
  >
    <AppIcon
      v-if="icon"
      :icon="icon"
      :is-raw="true"
    />

    {{ text }}
  </div>
</template>

<script setup>
defineProps({
    text: {
        type: String,
        default: '',
    },

    icon: {
        type: String,
        default: '',
    },

    hasInformation: {
        type: Boolean,
        default: false,
    },
});

const variant = inject('variant');
</script>

<style lang="scss" scoped>
.atom-flag {
    @include typo-font('regular');
    @include typo-size('button');
    @include z-index('flag');

    display: flex;
    align-items: center;
    padding: 15px 20px;
    background: $C_PRIMARY;
    border-bottom-left-radius: 6px;
    color: $C_WHITE;
    pointer-events: none;

    &.has-icon {
        .app-icon {
            width: 24px;
            height: 24px;
        }

    }

    .shop-product-card__inner & {
        border-top-left-radius: 6px;

        &:first-child {
            border-top-left-radius: 0px;
        }
    }

    &.is-other {
        background: $C_GREEN_DARK;
    }

    &.is-saner {
        background: $C_PRIMARY;
    }

    &.is-tipp {
        background: $C_ORANGE;
    }

    &.is-campaign-rose {
        background: $C_PINK;
    }

    &.is-campaign-orange {
        background: $C_ORANGE_STRONG;
    }

    &.is-newsletter {
        background: $C_BLUE;
    }
}
</style>
